import React from "react";
import styled from '@emotion/styled';
import translate from "../utils/translation";
import Layout from "../components/layout";
import Button from "../components/common/button";
import plus from '../../static/images/plus.svg';
import tick from '../../static/images/tick.svg';
import basicGraphic from '../../static/images/basic.svg';
import premiumGraphic from '../../static/images/premiumGraphic.svg';
import { MainTitle } from "../components/common/title";
import cloud from '../../static/images/cloud.svg';
import { getOS, download } from '../utils/browser';

const Wrapper = styled.section`
  height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  background: url(${cloud}) right center no-repeat, linear-gradient(to bottom, #FAE7C3, #FBF7F7);
  background-size: 90%;

  @media (max-width: 760px) {
    height: auto;
  }
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 760px) {
    flex-direction: column;
    padding-top: 200px;
  }
`;

const ContentLeft = styled.div`
  flex: 1;
  padding-right: 20px;

  @media (max-width: 990px) {
    padding: 0 24px 60px;
  }
`;

const ContentRight = styled.div`
  flex: 1;
  display: flex;
  position: relative;

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.88;
  margin: 0 0 16px;
`;

const Card = styled.div`
  width: 230px;
  height: 341px;
  border-radius: 8px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.1);
  background: #fbfbfb;
  margin: 0 18px;
  padding: 24px;
  position: relative;

  @media (max-width: 760px) {
    margin-bottom: 40px;
    height: auto;
  }

  button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    width: 153px;

    @media (max-width: 760px) {
      display: none;
    }
  }
`;

const CardTitleContainer = styled.div`
  padding-left: 110px;
  margin-bottom: 25px;

  &.basic {
    background: url(${basicGraphic}) left center no-repeat;
    background-size: 80px 88px;
  }

  &.premium {
    background: url(${premiumGraphic}) left center no-repeat;
    background-size: 88px 54px;
  }
`;

const CardTitle = styled.h2`
  margin: 0 0 24px;
  padding: 0;
  position: relative;
  color: #313436;
  line-height: 1.38;
  font-size: 16px;

  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 2px;
    background: #f38f8f;
    border-radius: 2px;
    left: 0;
    bottom: -4px;
  }
`;

const CardPriceTitle = styled.h4`
  margin: 0;
  padding: 0;
  color: #313436;
  font-size: 28px;
  line-height: 31px;
`;

const CardPriceDetail = styled.span`
  display: block;
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
`;

const FreeTitle = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 24px;
  height: 22px;
  background: #f38f8f;
  color: #fbfbfb;
  line-height: 1.42;
  letter-spacing: 0.3px;
  font-size: 12px;
  padding: 3px 9px 3px 16px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ListItem = styled.span`
  display: block;
  line-height: 16px;
  font-size: 12px;
  color: #313436;
  padding: 4px 0 4px 36px;
  background: url(${tick}) left center no-repeat;

  &.add {
    background: url(${plus}) left center no-repeat;
  }
`;

const PaymentNote = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
  color: #2f2f2f;
  line-height: 1.88;
  position: absolute;
  bottom: -90px;
  left: 0;
  width: 100%;

  @media (max-width: 760px) {
    position: static;
    padding: 20px 30px 93px;
  }
`;

export default () => {
  const os = getOS();
  return (
    <Layout activeTabIndex={3}>
      <Wrapper>
        <Container>
          <ContentLeft>
            <MainTitle>{translate("headers.pricing")}</MainTitle>
            <Content>{translate("price.desc")}</Content>
          </ContentLeft>
          <ContentRight>
            <Card>
              <CardTitleContainer className="basic">
                <CardTitle>{translate("price.basic.title")}</CardTitle>
                <CardPriceTitle>{translate("price.basic.free")}<CardPriceDetail>{translate("price.basic.forever")}</CardPriceDetail></CardPriceTitle>
              </CardTitleContainer>
              <ListItem>{translate("price.basic.newPage")}</ListItem>
              <ListItem>{translate("price.basic.dailyInsights")}</ListItem>
              <ListItem>{translate("price.basic.themes")}</ListItem>
              {
                (os === 'Mac OS' || os === 'Windows') &&
                <Button className="solid" width="100%" height="32px" onClick={() => download(os)}>{translate("getStarted")}</Button>
              }
            </Card>
            <Card className="premium">
              <FreeTitle>{translate("price.premium.title")}</FreeTitle>
              <CardTitleContainer className="premium">
                <CardTitle>{translate("price.premium.title1")}</CardTitle>
                <CardPriceTitle>{translate("price.premium.priceTag")}<CardPriceDetail>{translate("price.premium.monthly")}</CardPriceDetail></CardPriceTitle>
              </CardTitleContainer>
              <ListItem>{translate("price.premium.pwdProt")}</ListItem>
              <ListItem>{translate("price.premium.allInsights")}</ListItem>
              <ListItem>{translate("price.premium.backAndSync")}</ListItem>
              <ListItem>{translate("price.premium.darkMode")}</ListItem>
              <ListItem className="add">{translate("price.premium.basic")}</ListItem>
              {
                (os === 'Mac OS' || os === 'Windows') &&
                <Button className="solid" width="100%" height="32px" onClick={() => download(os)}>{translate("getStarted")}</Button>
              }
            </Card>
            <PaymentNote>{translate("price.premium.recurring")}</PaymentNote>
          </ContentRight>
        </Container>
      </Wrapper>
    </Layout>);
}